import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import LazySimpleImage from '../components/LazySimpleImage'
import Appear from '../components/Appear'
import LinkHP from '../components/LinkHP'
import { useLiterals } from 'store/'

const NotFoundPage = () => (
  <div className="h-screen text-white bg-black">
    <Layout>
      <Container>
        <div className="flex items-center justify-center h-screen">
          <Appear>
            <div className="h-12 mb-4 md:mb-8">
              <div className="flex items-center h-12">
                <LazySimpleImage
                  imageClassName="h-8 md:h-12"
                  image="/assets/static/hp.svg"
                  alt="HP Virtual Experience"
                />
                <span className="pr-12 ml-4 md:text-lg md:mr-0">
                  Page not found. <LinkHP to="/" className="text-white underline hover:no-underline">Go back to home</LinkHP>
                </span>
              </div>
            </div>
          </Appear>
        </div>
      </Container>
    </Layout>
  </div>  
)

export default NotFoundPage
